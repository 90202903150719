<template>
  <vue-perfect-scrollbar
    :settings="{suppressScrollX: true, wheelPropagation: false}"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%;"
  >
    <div class="pa-5 relative">
      <v-app-bar
        :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-4'"
        :dark="$vuetify.theme.dark"
        class="pt-1"
        fixed
        flat
      >
        <div class="d-flex justify-space-between align-center w-full">
          <h5 class="mb-0">
            My Profile
          </h5>
          <slot name="userDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>

    <div class="pa-5">
      <div class="mb-10">
        <base-card class="mt-4">
        <v-card-title class="pb-2">Account</v-card-title>
        <v-card-text>
          <p v-if="!enabledDisplayName" class="font-weight-medium ma-1 d-flex">{{ loggedInUser ? loggedInUser.userDetails.displayName : 'Log In'}} <v-icon class="ml-2" x-small @click="showDisplayNameEdit">mdi-pencil</v-icon></p>
          <v-text-field
            v-if="enabledDisplayName"
            v-model="updatedDisplayName"
            solo dense outlined
            hint="Display Name (leave blank to remove)"
            persistent-hint
            class="font-weight-medium" style="font-size: 0.875rem;">
            <template v-slot:append-outer>
              <v-icon @click="updateDisplayName" :disabled="disableUpdateDisplayName">mdi-content-save</v-icon>
            </template>
          </v-text-field>
          <p v-if="getErrorMessage" class="danger--text">{{ getErrorMessage }}</p>
          <p class="font-weight-medium ma-1" v-if="loggedInUser && loggedInUser.userDetails && loggedInUser.userDetails.displayName != playerName">{{ playerName }}</p>
          <p class="font-weight-medium ma-1">SHC 2025</p>
        </v-card-text>
        </base-card>
      </div>

      <div class="mb-10" v-if="loggedInUser">
        <base-card class="mb-3 mt-4">
        <v-card-title>Club</v-card-title>
        <v-card-text>
          <p v-if="!enabledClubSelection" class="font-weight-medium ma-1 d-flex">{{ updatedClub.name }} <v-icon class="ml-2" x-small @click="enabledClubSelection = true">mdi-pencil</v-icon></p>
          <v-combobox
            v-if="enabledClubSelection"
            solo dense outlined return-object
            v-model="updatedClub"
            :items="getClubs"
            item-value="id"
            :search-input.sync="search"
            @update:search-input
            item-text="name"
            hint="You can only be in one club at a time"
            persistent-hint
            class="font-weight-medium" style="font-size: 0.875rem;">
            <template v-slot:append>
              <v-icon @click="enabledClubSelection = !enabledClubSelection">mdi-close-circle-outline</v-icon>
            </template>
            <template v-slot:append-outer>
              <v-icon @click="updateClubClick" :disabled="disableUpdateDisplayName">mdi-content-save</v-icon>
            </template>
            <template v-slot:item="{ item }">
              <div>
                <strong>{{ item.name }}</strong> - {{ item.location }}
              </div>
            </template>
          </v-combobox>
          <p v-if="!enabledClubSelection" class="font-weight-medium ma-1 d-flex">{{ updatedClub.location }}</p>
        </v-card-text>
        </base-card>
      </div>

      <div class="mb-10">
        <base-card class="mb-3 mt-4">
        <v-card-title>Theme</v-card-title>
        <v-card-text>
          <div v-if="!$vuetify.theme.dark">
            <div style="display: flex;">
            <v-btn
                @click="changeDarkMode(true)"
                class="mr-4 mb-4"
                color="dark"
                small
                fab
                dark
              >
                <v-icon>mdi-weather-night</v-icon>
              </v-btn>
              <div class="mt-3">Night</div>
            </div>
          </div>
          <div v-else>
            <div style="display: flex;">
              <v-btn
                @click="changeDarkMode(false)"
                class="mr-4 mb-4"
                color="white"
                small
                fab
              >
                <v-icon color="warning">mdi-white-balance-sunny</v-icon>
              </v-btn>
              <div class="mt-3">Day</div>
              </div>
          </div>
          <div style="display: flex;">
          <v-btn
            @click="changeDarkMode(false)"
            class="mr-4 mb-4"
            color="white"
            small
            fab
            disabled
          >
            <v-icon color="warning">mdi-tshirt-crew-outline</v-icon>
          </v-btn>
            <div class="mt-3">Club</div>
          </div>
        </v-card-text>
        </base-card>
      </div>
    </div>
  </vue-perfect-scrollbar>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'UserDrawer',
    props: {},
    data () {
      return {
       enabledDisplayName: false,
       updatedDisplayName: '',
       enabledClubSelection: false,
       updatedClub: { "name": null, "location": null},
       search: ''
      }
    },
    mounted(){
      //this.fetchClubs();
      if(this.loggedInUser && this.$router.currentRoute.meta.sport){
        this.fetchPersonalLeagues({userId: this.loggedInUser.userDetails.id, sport: this.$router.currentRoute.meta.sport.toUpperCase()});
        if(this.loggedInUser && this.loggedInUser.userDetails){
          this.updatedClub = { "name": this.loggedInUser.userDetails.clubName, "id": this.loggedInUser.userDetails.clubId, "location": this.loggedInUser.userDetails.clubLocation};
        }
      }
    },
    computed: {
      ...mapGetters(["loggedInUser", "getErrorMessage", "getClubs", "getUserClub", "getUserParticipation"]),
      playerName(){
        return this.loggedInUser.userDetails.firstName + ' ' + this.loggedInUser.userDetails.lastName;
      },
      disableUpdateDisplayName(){
        return this.updatedDisplayName .length < 3 && this.updatedDisplayName.length != 0;
      }
    },
    methods: {
      ...mapActions(["updateUserDisplayname", "fetchClubs", "searchClubs", "updateClub", "fetchPersonalLeagues", "userPaid"]),
      changeDarkMode(data) {
        this.$vuetify.theme.dark = data;
      },
      updateDisplayName(){
        this.enabledDisplayName = !this.enabledDisplayName;
        this.loggedInUser.userDetails.displayName = this.updatedDisplayName;
        if(this.updatedDisplayName == ''){
          this.enabledDisplayName = false;
        }
        this.updateUserDisplayname({"userId": this.loggedInUser.userDetails.id, "displayName":this.updatedDisplayName});
      },
      showDisplayNameEdit(){
        this.enabledDisplayName = !this.enabledDisplayName;
        this.updatedDisplayName = this.loggedInUser.userDetails.displayName;
      },
      updateClubClick(){
        this.updatedClub = this.updatedClub;
        this.enabledClubSelection = !this.enabledClubSelection;
        this.updateClub({"userId" : this.loggedInUser.userDetails.id, "clubId": this.updatedClub.id })
      },
      onSearch(search){
        this.searchClubs(search);
      },
    },
    watch: {
      search(val){
        if(val){
          this.onSearch(val);
        } else {
          this.clubs = [];
        }
      },
      getUserClub(){
        this.fetchPersonalLeagues(this.loggedInUser.userDetails.id);
      }
    }
  }
</script>

<style scoped></style>
